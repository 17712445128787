@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'VT323', monospace;
}

body {
  background-color: #e9e7db;
}

::-webkit-scrollbar {
  display: none;
}

.active-tab {
  background-color: #e9e7db;
  border-color: #fb1b1b !important;
  pointer-events: none !important;
}

.active-pokedex {
  background-color: #e9e7db;
  border-color: #0a285f !important;
  pointer-events: none !important;
}

.progress-line,
.progress-line:before {
  height: 5px;
  width: 100%;
  margin: 0;
}

.progress-line {
  background-color: #ee9191;
  display: -webkit-flex;
  display: flex;
}

.progress-line:before {
  background-color: #fb1b1b;
  content: '';
  -webkit-animation: running-progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation: running-progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@-webkit-keyframes running-progress {
  0% {
    margin-left: 0px;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0%;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}
@keyframes running-progress {
  0% {
    margin-left: 0px;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0%;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}
